<template>
  <main>
    <make-banner height="600" :id="23"></make-banner>
    <recomend-list></recomend-list>
    <!-- 促销 -->
    <article class="activity row-between core">
      <!-- 限时折扣 -->
      <router-link
        to="/discount"
        class="activity-item activity-r pr"
        :style="`background-image: url(${config.discount_bgimage})`"
      >
        <!-- <div class="item-pro column pa">
            <img
              src="https://img.alicdn.com/imgextra/i1/2168653906/O1CN013IKDoE1eizkrnDMdm_!!2168653906.jpg_430x430q90.jpg"
              alt=""
            />
          </div>
          <div class="item-info pa white column">
            <div class="info-title row mb10">
              <img src="@/assets/icon/icon-time.png" alt="" />
              <span>限时折扣</span>
            </div>
            <div class="info-subTitle row">
              <span>限时限量</span>
              <img class="ml10" src="@/assets/icon/icon-arrow.png" alt="" />
            </div>
          </div> -->
      </router-link>
      <!-- 促销满减 -->
      <router-link
        to="/reduction"
        class="activity-item activity-l pr"
        :style="`background-image: url(${config.sales_bgimage})`"
      >
        <!-- <div class="item-pro column pa">
            <img
              src="https://img.alicdn.com/imgextra/i1/2168653906/O1CN013IKDoE1eizkrnDMdm_!!2168653906.jpg_430x430q90.jpg"
              alt=""
            />
          </div>
          <div class="item-info pa white column">
            <div class="info-title row mb10">
              <img src="@/assets/icon/icon-sales.png" alt="" />
              <span>促销满减</span>
            </div>
            <div class="info-subTitle row">
              <span>爆款清单</span>
              <img class="ml10" src="@/assets/icon/icon-arrow.png" alt="" />
            </div>
          </div> -->
      </router-link>
    </article>
    <module-list></module-list>
  </main>
</template>

<script>
import RecomendList from "../components/RecomendList";
import ModuleList from "../components/ModuleList";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    "recomend-list": RecomendList,
    "module-list": ModuleList,
  },
  data() {
    return {
      floorGoodList: [], //楼层数据
      indexData: {}, //首页数据
    };
  },
  computed: {
    ...mapState(["config"]),
  },
  created() {
    this.getIndex();
  },
  methods: {
    /**
     * @description 获取首页数据
     * @return void
     */
    getIndex() {
      this.$api.getIndexDataApi().then((res) => {
        this.indexData = res.data;
      });
    },
    /**
     * @description 获取首页热门关键词
     * @return void
     */
    getSearchList() {
      this.$api.getSearchListApi().then((res) => {
        this.serachList = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 促销
.activity {
  margin-bottom: 67px;
  .activity-item {
    width: 592px;
    height: 340px;
    background-size: cover;
    background-position: center;
    .item-pro {
      width: 390px;
      height: 260px;
      top: 50%;
      transform: translateY(-50%);
      border: 4px solid $-color-primary;
      padding: 0 18px;
      img {
        width: 284px;
        height: 190px;
      }
    }
    .item-info {
      width: 200px;
      height: 100px;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      background-color: $-color-primary;
      padding-right: 30px;
      align-items: flex-end;
      line-height: 1;
    }
    .info-title {
      font-size: 28px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 14px;
      }
    }
    .info-subTitle {
      img {
        width: 8px;
        height: 5px;
        transform: rotate(-90deg);
      }
    }
    &.activity-l {
      .item-pro {
        align-items: flex-end;
        border-color: #38888b;
        right: 0;
      }
      .item-info {
        left: 40px;
        background-color: #38888b;
      }
    }
  }
}
</style>